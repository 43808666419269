import React, {Component} from 'react';
import SectitleMulti from './Title/SectitleMulti';
import Sectitle from './Title/Sectitle';

class Price extends Component {
    render(){
        return(
            <section className="support_price_area sec_pad" id="price">
                <div className="container custom_container p0">
                    <SectitleMulti sClass="sec_title text-center mb_70" Title='Le Licenze Parkingless' TitleP='Parkingless permette di avere prezzi dedicati in base alle dimensioni del tuo Business.' TitleP1='Tutti i pagamenti sono sicuri e gestiti tramite tecnologia PAYPAL' TitleP2='Contattaci per ottenere fino a 6 mesi di prova gratuita' />
                    <div className="price_content price_content_two">
                        <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="price_item">
                                <div className="tag"><span>Small</span></div>
                                <h5 className="f_p f_size_24 f_600 t_color2 mt_30">Licenza Base</h5>
                                <div className="price f_700 f_size_40 t_color2">30.00<sub className="f_size_16 f_400">/ mese</sub></div>
                                <ul className="list-unstyled p_list">
                                    <li><i className="ti-check"></i><strong>1</strong> Garage</li>
                                    <li><i className="ti-check"></i>Max <strong>100</strong> Clienti</li>
                                    <li><i className="ti-check"></i><strong>2</strong> Utenze Amministratore</li>
                                    <li><i className="ti-check"></i><strong>10</strong> Utenze Dipendente</li>
                                </ul>
                                <a href="https://parkingless.com" className="price_btn btn_hover">Inizia Subito</a>

                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="price_item">
                                <div className="tag"><span>Medium</span></div>
                                <h5 className="f_p f_size_24 f_600 t_color2 mt_30">Licenza Oro</h5>
                                <div className="price f_700 f_size_40 t_color2">€60.00<sub className="f_size_16 f_400">/ mese</sub></div>
                                <ul className="list-unstyled p_list">
                                    <li><i className="ti-check"></i>Max <strong>3</strong> Garage</li>
                                    <li><i className="ti-check"></i>Max <strong>500</strong> Clienti</li>
                                    <li><i className="ti-check"></i><strong>6</strong> Utenze Amministratore</li>
                                    <li><i className="ti-check"></i><strong>30</strong> Utenze Dipendente</li>
                                </ul>
                                <a href="https://parkingless.com" className="price_btn btn_hover">Inizia Subito</a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="price_item">
                                <div className="tag"><span>Large</span></div>
                                <h5 className="f_p f_size_24 f_600 t_color2 mt_30">Licenza Premium</h5>
                                <div className="price f_700 f_size_40 t_color2">€150.00<sub className="f_size_16 f_400">/ mese</sub></div>
                                <ul className="list-unstyled p_list">
                                <li><i className="ti-check"></i>Max <strong>5</strong> Garage</li>
                                    <li><i className="ti-check"></i>Max <strong>3000</strong> Clienti</li>
                                    <li><i className="ti-check"></i><strong>20</strong> Utenze Amministratore</li>
                                    <li><i className="ti-check"></i><strong>100</strong> Utenze Dipendente</li>
                                </ul>
                                <a href="https://parkingless.com" className="price_btn btn_hover">Inizia Subito</a>
                            </div>
                        </div>
                        </div> 
                    </div>
                </div>
            </section>
        )
    }
}
export default Price;