import React from 'react';
import OnepageMenu from '../components/OnepageMenu';
import AppBanner from '../components/Banner/AppBanner';
import AppFeatures from '../components/Features/AppFeatures';
import ScreenshowCase from '../components/ScreenshowCase';
import AppGetstarted from '../components/AppGetstarted';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Service from '../components/Service/Service';
import SecurityFeatures from '../components/Features/SecurityFeatures'
import Price from '../components/Price';
import SupportItem from '../components/Service/SupportItem';

const Landing = () => {
    return(
        <div className="body_wrapper">
            <OnepageMenu slogo="sticky_logo" mClass="menu_four" nClass="w_menu" hbtnClass="btn_get_radious menu_custfive"/>
            <AppBanner/>
            <Service/>
            <SecurityFeatures/>
            <AppFeatures/>
            <Price></Price>
            <SupportItem></SupportItem>
            <ScreenshowCase/>
            <AppGetstarted/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default Landing;