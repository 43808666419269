import React from 'react';
import Reveal from 'react-reveal';
const AppFeatures = () => {
    return(
        <section className="app_featured_area_two" id='work'>
            <div className="triangle_shape"></div>
            <div className="container">
                <div className="row app_feature_info">
                    <div className="col-lg-5">
                        <div className="app_img">
                            <div className="dot dot_one wow fadeIn" data-wow-delay="0.5s"><span className="dot1"></span><span className="dot2"></span></div>
                            <div className="dot dot_two wow fadeIn" data-wow-delay="0.8s"><span className="dot1"></span><span className="dot2"></span></div>
                            <div className="dot dot_three wow fadeIn" data-wow-delay="0.3s"><span className="dot1"></span><span className="dot2"></span></div>
                            <Reveal effect="fadeInLeft" duration={2500}><img className="text_bg one" src={require('../../img/home7/text_one.png')} alt=""/></Reveal>
                            <Reveal effect="fadeInDown" duration={2000}><img className="text_bg two" src={require('../../img/home7/text_three.png')} alt=""/></Reveal>
                            <Reveal effect="fadeInRight" duration={1500}><img className="text_bg three" src={require('../../img/home7/text_two.png')} alt=""/></Reveal>
                            <img className="wow fadeIn phone_img" src={require('../../img/home7/iPhonex.png')} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="app_featured_content">
                                <h2 className="f_p f_size_30 f_700 t_color3 l_height45 mb-30">Con la nostra applicazione mobile,<br/> gestire il tuo garage diventa semplice come utilizzare uno smartphone.</h2>
                                <p className="f_400">Configura facilmente i tuoi amministratori e dipendenti per consentire loro di gestire le funzionalità della piattaforma direttamente dal loro telefono. Grazie alla nostra innovativa soluzione, i tuoi dipendenti potranno gestire i servizi da erogare o le anagrafiche senza dover utilizzare un computer. Rendi la gestione del tuo garage più efficiente e moderna con la nostra app mobile. Scopri di più sulle nostre funzionalità sul nostro sito.</p>
                                {/* <a href=".#" className="app_btn btn_hover mt_40">Learn More</a> */}
                            </div>
                        </Reveal>
                        
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AppFeatures;
