import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ScrollToTopRoute from "./ScrollToTopRoute";
import Landing from "./Pages/Landing";

const firebaseConfig = {
  apiKey: "AIzaSyCedmJpCOjfSNKMVeMO2b6T10MbfNyFWe0",
  authDomain: "parkingless-home.firebaseapp.com",
  projectId: "parkingless-home",
  storageBucket: "parkingless-home.appspot.com",
  messagingSenderId: "900715339743",
  appId: "1:900715339743:web:6da491eb38dfb76de6ee6a",
  measurementId: "G-95LH8PY9M3"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={Landing} />
        </Switch>
      </Router>
    );
  }
}

export default App;
