import React from 'react';
import SeoTitle from '../Title/SeoTitle';
import Fade from 'react-reveal/Fade';

const Service = () => {
    return(
        <React.Fragment>
            <section className="analytices_list_area security_list_area" id='features'>
            <img className="macbook_a" src={require ("../../img/home-security/mackbook.png")} alt=""/>
            {/* <img className="lock" src={require ("../../img/home-security/padlock.png")} alt=""/> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="hosting_title analytices_title">
                            <h2>Il tuo Garage a portata di click</h2>
                            <p>Il nostro gestionale completo offre un ampio ventaglio di servizi a 360° con prezzi su misura per la tua attività. <br></br>
                            Potrai gestire l'anagrafica clienti e veicoli, generare ed inviare ricevute, gestire ogni tipo di Sosta ed offrire molti altri servizi ai tuoi clienti. <br></br>
                            La nostra piattaforma integrata su web e mobile è in costante sviluppo e fornisce funzionalità altamente innovative, come la scansione della patente attraverso la fotocamera per un onboarding veloce e senza intoppi.<br></br> 
                            Siamo la soluzione ideale per gestire la tua attività in modo efficiente e moderno.</p>
                        </div>
                        {/* <ul className="analytices_list">
                            <li>Anagrafica Clienti</li>
                            <li>Anagrafica Veicoli</li>
                            <li>Ricevute</li>
                            <li>Servizi</li>
                            <li>Reminders</li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </section>
        </React.Fragment>
    )
}

export default Service;
