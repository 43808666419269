import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal';
class SectitleMulti  extends Component {
    render(){
        var {Title, TitleP, TitleP1, TitleP2 , tClass,sClass} = this.props;
        return(
            <div className={`${sClass}`}>
                <Reveal effect="fadeInUp" duration={1300}><h2 className={`f_p f_size_30 l_height50 f_600 ${tClass}`}>{Title}</h2></Reveal>
                <Reveal effect="fadeInUp" duration={1600}><p className="f_400 f_size_16 mb-0">{TitleP}</p></Reveal>
                <Reveal effect="fadeInUp" duration={1900}><p className="f_400 f_size_16 mb-0">{TitleP1}</p></Reveal>
                <Reveal effect="fadeInUp" duration={2200}><p className="f_400 f_size_16 mb-0"><strong>{TitleP2}</strong></p></Reveal>

            </div>
        )
    }
}
export default SectitleMulti;
