import React from 'react';
import SecurityFeaturesItem from './SecurityFeaturesItem';

const SecurityFeatures =()=>{
    return(
        <section className="h_security_area">
            <div className="container">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s">Cosa Puoi Fare con Parkingless Parkingless?</h2>
                </div>
                <div className="row">
                    <SecurityFeaturesItem itemClass="pr_70" image="garage.png" Title="Configura il tuo Garage" pText="Crea i tuoi prezziari, i tuoi servizi ed aggiungi i tuoi collaboratori"/>
                    <SecurityFeaturesItem itemClass="pl_70" image="patente.png" Title="Gestisci i tuoi clienti" pText="Aggiungere un cliente non è mai stato così semplice: scatta una foto della patente ed avrai i suoi dati automaticamente compilati"/>
                    <SecurityFeaturesItem itemClass="pr_70" image="ricevute.png" Title="Genera Ricevute dei tuoi Abbonati" pText="Basta un click per generare tutte le ricevute dei tuoi abbonati. Potrai scaricarle o inviarle direttamente via email"/>
                    <SecurityFeaturesItem itemClass="pl_70" image="thermalprinter.png" Title="Soste Orarie" pText="Inquadra la targa dal tuo smarphone, Scatta e stampa il ticket senza dipendere più da tipografie esterne. Al ritorno basterà inquadare il QR Code sulla ricevuta per generare automaticamente il costo della sosta"/>
                    <SecurityFeaturesItem itemClass="pr_70" image="calendar.png" Title="Pianifica ed Offri i tuoi Servizi" pText="Grazie alla nostra agenda digitale potrai pianificare i servizi che offri ai tuoi clienti ed inserirli direttamente nelle ricevute abbonamento"/>
                    <SecurityFeaturesItem itemClass="pl_70" image="payments.png" Title="Monitora i Pagamenti" pText="Mantiene sotto controllo tutti i pagamenti, genera in un click il conto cassa giornaliero o mensile"/>
                    <SecurityFeaturesItem itemClass="pr_70" image="clienti.png" Title="Comunica con i tuoi Clienti" pText="Grazie all'app dedicata i clienti potranno richiedere i servizi da te offerti. Potrai sempre monitarli nell'agenda dedicata"/>

                </div>
            </div>
        </section>
    )
}
export default SecurityFeatures;